import { gsap } from "gsap"
import { PixiPlugin } from "gsap/PixiPlugin.js"
import { MotionPathPlugin } from "gsap/MotionPathPlugin.js"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import $ from 'jquery';

//without this line, PixiPlugin and MotionPathPlugin may get dropped by your bundler (tree shaking)...
gsap.registerPlugin(PixiPlugin, MotionPathPlugin, ScrollTrigger);

$(document).on('turbolinks:load', function() {
  const missions = gsap.utils.toArray('.animate-content-right');
  missions.forEach(mission => {
    gsap.fromTo (mission , { x: -300, opacity: 0, delay: 0.5},
    { x: 0, opacity: 1, duration: 3, scrollTrigger: {
      trigger: mission,
      start: "top 90%",
      end: "top 35%",
      scrub: 1
    }});
  });
  const visions = gsap.utils.toArray('.animate-content-left');
  visions.forEach(vision => {
    gsap.fromTo (vision , { x: 300, opacity: 0, delay: 0.5},
    { x: 0, opacity: 1, duration: 3, scrollTrigger: {
      trigger: vision,
      start: "top 90%",
      end: "top 35%",
      scrub: 1
      // markers: true,
    }});
  });
});
