//= require tinymce
//= require ckeditor/init
import $ from 'jquery'
import 'bootstrap/dist/js/bootstrap.bundle'
import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import "channels"
require("jquery")
import 'regenerator-runtime/runtime'

require("./swiper")
require("./animation")

Rails.start()
Turbolinks.start()

const WOW = require('wowjs');
var scrollvalue = 0;
var new_scrollvalue = 0;

$(document).on('turbolinks:load', function() {
  $(document).ready(function(){
    var wow = new WOW.WOW(
      {
        live: false,
        boxClass:     'wow',      // animated element css class (default is wow)
        animateClass: 'animated', // animation css class (default is animated)
        offset:       0,          // distance to the element when triggering the animation (default is 0)
        mobile:       true,       // trigger animations on mobile devices (default is true)    // act on asynchronously loaded content (default is true)
        callback:     function(box) {
          // the callback is fired every time an animation is started
          // the argument that is passed in is the DOM node being animated
        },
        scrollContainer: null // optional scroll container selector, otherwise use window
      }
    );

    document.wow = wow;
    wow.init();
  });
});

document.addEventListener('turbolinks:load', function() {

  const anchors = document.querySelectorAll('a[href*="#"]')

  for (let anchor of anchors) {
    anchor.addEventListener('click', function (e) {
      e.preventDefault()
      const blockID = anchor.getAttribute('href').substr(1)

      document.getElementById(blockID).scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      })
    })
  }
})


$(document).on('turbolinks:load', function() {
  $(document).ready(function(){
    $('button.navbar-toggler').click(function(){
      scrollvalue = $(window).scrollTop();
      $('.navbar-collapse').toggleClass('collapse');
    });

    window.copyLink = function copyLink(pathURL) {
      var dummy = document.createElement('input')
      document.body.appendChild(dummy);
      dummy.value = pathURL;
      dummy.select();
      document.execCommand('copy');
      document.body.removeChild(dummy);
      console.log(dummy.value);
    }

  $(window).scroll(function () {
    if (! $('.navbar-collapse.collapse').hasClass("collapse")) {
      if( window.innerWidth >= 767 ){
        if (($(window).scrollTop() - scrollvalue) > 500) {
          new_scrollvalue = $(window).scrollTop();
          $('.navbar-collapse').addClass('collapse');
        }
      } else {
        if (($(window).scrollTop() - scrollvalue) > 350) {
          new_scrollvalue = $(window).scrollTop();
          $('.navbar-collapse').addClass('collapse');
        }
      }
    }});
  });
});

$(document).on('turbolinks:load', function() {
  $('.validateContactform').keyup(function () {
    styleContactSubmitbutton(isContactValid());
  });

  $(document).on("input", "input.contacts_phone", function() {
    if (($('.contacts_phone').val()).length > 9 || ($('.contacts_phone').val()).length == 0) {
      $(".number_phone_warning").removeClass('active');
    } else {
      $(".number_phone_warning").addClass('active');
    }
  });

  function styleContactSubmitbutton(isValidated) {
    let submit_button = document.getElementById("btn-contact");

    if (isValidated) {
      submit_button.disabled = false
      submit_button.style.backgroundColor = "#EE4D4D", submit_button.style.color = "#fff";
    } else {
      submit_button.style.backgroundColor = "#889ca6", submit_button.style.color = "#fff";
      submit_button.disabled = true
    }
  };

  function isContactValid() {
    if(!$('#contact_name').val()) { return }
    if (($('#contact_phone').val()).length < 10) { return }
    if(!$('#contact_message').val()) { return }

    return true;
  };
});


$(document).on('turbolinks:load', function() {
  $(".dropdown__links").click(function() {
    if ($(".dropdown-menu").hasClass("d-none")) {
      $(".dropdown-toggle").addClass("active");
      $(".dropdown-menu").removeClass("d-none");
      $(".dropdown-menu").addClass("d-block");
    }
    else {
      $(".dropdown-toggle").removeClass("active");
      $(".dropdown-menu").removeClass("d-block");
      $(".dropdown-menu").addClass("d-none");
    }
  });
});

$(document).on('turbolinks:load', function() {
  $('.news__icon.copy').click(function() {
    $("#copied-link").show();
    setTimeout(function() { $("#copied-link").hide('fast'); }, 2000);
  });
});


//============CURSOR======================


document.addEventListener('turbolinks:load', function () {
  const cursorState = localStorage.getItem('cursorState');
  if (cursorState === 'clicked') {
    document.body.classList.add('clicked');
  } else {
    document.body.classList.remove('clicked');
  }

  document.querySelectorAll('body *').forEach(function (element) {
    element.addEventListener('mousedown', function () {
      element.classList.add('clicked');
      localStorage.setItem('cursorState', 'clicked');
    });

    element.addEventListener('mouseup', function () {
      element.classList.remove('clicked');
      localStorage.setItem('cursorState', 'default');
    });
  });
});