//= require swiper
// import Swiper from 'swiper';
import Swiper, {
  Autoplay,
  Navigation,
  Pagination
} from "swiper";
import $ from 'jquery';

$(document).on('turbolinks:load', function() {
  var mySwiper = new Swiper('.swiper-container', {
    freeMode: true,
    cssMode: true,
    breakpoints: {
      600: {
          slidesPerView: 1,
      },
      200: {
        slidesPerView: 0.5,
      },
    }
  });

  var swiper = new Swiper(".clients-swiper", {
    modules: [Autoplay],
    loop: true,
    slidesPerGroup: 1,
    slidesPerView: "auto",
    spaceBetween: 0,
    speed: 4500,
    autoplay: {
      delay: 1,
      enabled: true,
      disableOnInteraction: false,
    },
  });
  var resultSwiper = new Swiper('.result-slider', {
    modules: [Navigation, Pagination, Autoplay],
    loop: true,
    speed: 4500,
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
    navigation: {
      nextEl: '.result-swiper-button-next',
      prevEl: '.result-swiper-button-prev',
    },
    autoplay: {
      delay: 4500,
      disableOnInteraction: false
    }
  });
});
